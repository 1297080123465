// -----------------------------------------------------------------------------
// Theme
// -----------------------------------------------------------------------------

@use 'theme/variables' as *;
@use 'theme/main' as *;
@use 'theme/components' as *;
@use 'theme/mapbox' as *;
@use 'theme/forms' as *;

// -----------------------------------------------------------------------------
// Ionic
// -----------------------------------------------------------------------------

@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import '@ionic/angular/css/palettes/dark.class.css';