// -----------------------------------------------------------------------------
// Light
// -----------------------------------------------------------------------------

:root {

  // Primary
  --ion-color-primary: #625AEC;
  --ion-color-secondary: #27325E;
  --ion-color-tertiary: #8BA2C7;
  --ion-color-success: #0FD59A;
  --ion-color-warning: #F7930D;
  --ion-color-danger: #DB637E;
  --ion-color-dark: #27325E;
  --ion-color-light: #FFFFFF;

  // Components
  --ion-color-background: #FFFFFF;
  --ion-color-components: #F8F9FC;
  --ion-color-borders: #F1F4FB;
  --ion-color-shadow: 0 1px 1px rgba(0, 0, 0, .02);
  --ion-color-textshadow: none;

  // Backdrop
  --ion-backdrop-color: #0C131D;
  --ion-backdrop-opacity: .7;

  // Gradients
  --ion-gradient-places: linear-gradient(to bottom, rgba(248, 249, 252, 0.75) 0%, rgba(248, 249, 252, 0) 100%);
  --ion-gradient-header: linear-gradient(to bottom, rgba(20, 24, 34, 0.75) 0%, rgba(20, 24, 34, 0) 100%);
  --ion-gradient-footer: linear-gradient(to bottom, rgba(20, 24, 34, 0) 40%, rgba(20, 24, 34, 0.3) 60%, rgba(20, 24, 34, 0.9) 100%);
  --ion-gradient-card: linear-gradient(to bottom, rgba(20, 24, 34, 0) 40%, rgba(20, 24, 34, 0.3) 60%, rgba(20, 24, 34, 0.8) 100%);
  --ion-gradient-primary: linear-gradient(0deg, rgba(121, 76, 248, 1) 10%, rgba(121, 76, 248, 0.9) 35%, rgba(121, 76, 248, 0) 100%);
}

// -----------------------------------------------------------------------------
// Dark
// -----------------------------------------------------------------------------

html.ion-palette-dark {

  // Colors
  --ion-color-secondary: #ECF1FF;

  // Components
  --ion-color-background: #141822;
  --ion-color-components: #181C27;
  --ion-color-borders: #1E222F;
  --ion-color-segmemt: #272E3A;
  --ion-color-shadow: 0 1px 1px rgba(39, 50, 94, .06);
  --ion-color-textshadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

  // Backdrop
  --wheel-fade-background-rgb: 18, 25, 36;
  --ion-backdrop-color: #070c12;
  --ion-backdrop-opacity: .9;

  // Gradients
  --ion-gradient-places: linear-gradient(to bottom, rgba(20, 24, 34, 0.75) 0%, rgba(20, 24, 34, 0) 100%);
  --ion-gradient-header: linear-gradient(to bottom, rgba(20, 24, 34, 0.75) 0%, rgba(20, 24, 34, 0) 100%);
}

// -----------------------------------------------------------------------------
// Styles
// -----------------------------------------------------------------------------

:root {

  // Grid
  --grid-xxxs: 4px;
  --grid-xxs: 8px;
  --grid-xs: 12px;
  --grid-s: 16px;
  --grid-m: 20px;
  --grid-l: 24px;
  --grid-xl: 28px;
  --grid-xxl: 32px;
  --grid-xxxl: 64px;

  // Sizes
  --size-icon: 24px;
  --size-element: 44px;

  // Radius
  --radius-s: 11px;
  --radius-m: 22px;
  --radius-l: 38px;

  // Sizes
  --font-xxs: 11px;
  --font-xs: 12px;
  --font-s: 14px;
  --font-m: 16px;
  --font-l: 20px;
  --font-xl: 24px;
  --font-xxl: 32px;

  // Weights
  --font-regular: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  // Family
  --font-family: 'OpenRunde', system-ui, sans-serif !important;
  --ion-font-family: 'OpenRunde', system-ui, sans-serif !important;
  --ion-default-font: 'OpenRunde', system-ui, sans-serif !important;

  // Imports
  @font-face {
    font-family: 'OpenRunde';
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    src: url("/assets/fonts/openrunde-regular.woff2") format('woff2');
  }

  @font-face {
    font-family: 'OpenRunde';
    font-display: swap;
    font-weight: 500;
    font-style: normal;
    src: url("/assets/fonts/openrunde-medium.woff2") format('woff2');
  }

  @font-face {
    font-family: 'OpenRunde';
    font-display: swap;
    font-weight: 600;
    font-style: normal;
    src: url("/assets/fonts/openrunde-semibold.woff2") format('woff2');
  }

  @font-face {
    font-family: 'OpenRunde';
    font-display: swap;
    font-weight: 700;
    font-style: normal;
    src: url("/assets/fonts/openrunde-bold.woff2") format('woff2');
  }
}