// -----------------------------------------------------------------------------
// Header
// -----------------------------------------------------------------------------

ion-header {
  --background: var(--ion-color-background);
  box-shadow: none !important;

  ion-toolbar {
    --background: var(--ion-color-background);
    --padding-start: var(--grid-s) !important;
    --padding-end: var(--grid-s) !important;
    --padding-bottom: var(--grid-xs) !important;
    --padding-top: var(--grid-xs) !important;
    --border-width: 0 !important;
    --border-color: var(--ion-color-borders);
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;

    // Title

    ion-title {
      padding-left: calc(var(--grid-xxxl) + var(--grid-xs));
      font-size: var(--font-l);
      font-weight: var(--font-bold);
      color: var(--ion-color-secondary);
      text-align: left;

      &.tab {
        font-size: var(--font-xl);
        padding-left: var(--grid-m);
      }
    }

    // Back

    ion-back-button {
      background-color: var(--ion-color-components);
      margin-left: 0 !important;
      width: var(--size-element);
      height: var(--size-element);
      text-align: center;
      border-radius: var(--radius-m);
      border: 1px solid var(--ion-color-borders);
    }

    ion-back-button {
      padding: 0 0 0 var(--grid-xxxs) !important;
    }

    // Buttons

    ion-buttons {
      padding: var(--grid-xxxs) 0 var(--grid-xxxs) var(--grid-xxxs);

      ion-button {
        margin-left: 0 !important;
        --background: transparent;
        min-width: var(--size-element);
        height: var(--size-element);

        &:last-child {
          margin-left: var(--grid-xxs) !important;
        }

        &:hover {
          opacity: .8;
        }

        &.label ion-label {
          display: block !important;
        }

        &.avatar {
          background-size: cover;
          background-color: var(--ion-color-components);
          height: var(--size-element);
          width: var(--size-element);
          border-radius: var(--radius-m);
          margin-left: var(--grid-xs) !important;
          border: 1px solid var(--ion-color-borders);
        }

        &.expand {
          display: none;
        }

        ion-icon {
          --width: var(--size-icon);
          --height: var(--size-icon);

          &[hidden] {
            display: none !important;
          }
        }

        ion-label {
          display: none;
          padding: 0 0 0 var(--grid-xxs);
          font-size: var(--font-s);
          font-weight: var(--font-semibold);
          color: var(--ion-color-secondary);

          @media only screen and (min-width: 500px) {
            display: block;
          }

          &[hidden] {
            display: none !important;
          }
        }

        .badge {
          position: absolute;
          background-color: var(--ion-color-danger);
          top: -6px;
          right: -8px;
          width: var(--grid-m);
          height: var(--grid-m);
          animation: pulseRed 2s infinite;
          border: var(--grid-xxxs) solid var(--ion-color-background);
          border-radius: 10px;
          z-index: 1;
        }

        &.component {
          background-color: var(--ion-color-components);
          padding: 0 var(--grid-xxs);
          height: var(--size-element);
          width: auto;
          font-size: var(--fonts-m);
          font-weight: 800;
          color: var(--ion-color-warning);
          border-radius: var(--radius-m);
          border: 1px solid var(--ion-color-borders);

          ion-icon {
            margin: -2px var(--gris-xxxs) 0 0;
            color: var(--ion-color-warning);
            vertical-align: middle;
          }

          ion-spinner {
            margin: -2px 0 0 0;
            color: var(--ion-color-warning);
            vertical-align: middle;
          }

          &.primary {
            background-color: var(--ion-color-primary);

            ion-label {
              color: white;
            }
          }

          &.warning {
            ion-label {
              color: var(--ion-color-warning);
            }
          }
        }

        &.filled {
          --background: var(--ion-color-components) !important;
          --padding-start: var(--grid-s);
          --padding-end: var(--grid-s);
          --border-radius: var(--radius-m) !important;
          --border-width: 0;
          margin-left: var(--grid-xs) !important;
          min-width: var(--size-element);
          height: var(--size-element);

          ion-label {
            color: var(--ion-color-secondary);
          }

          &.dark {
            --background: var(--ion-color-dark) !important;

            ion-label {
              color: var(--ion-color-light);
            }
          }
        }
      }

      &[slot="start"] ion-button {
        background-color: var(--ion-color-components);
        margin-left: 0 !important;
        width: var(--size-element);
        height: var(--size-element);
        text-align: center;
        border-radius: var(--radius-m);
        border: 1px solid var(--ion-color-borders);
      }
    }

    // Search

    ion-input {
      background-color: var(--ion-color-components);
      background-image: url("/assets/icons/color/search-refraction.svg") !important;
      background-repeat: no-repeat !important;
      background-position: var(--grid-xs) var(--grid-xs) !important;
      background-size: 18px !important;
      padding: 0 var(--grid-m) 0 40px;
      height: var(--radius-l);
      width: 100%;
      font-size: var(--font-s);
      font-weight: var(--font-semibold);
      height: var(--radius-l) !important;
      text-align: left;
      color: var(--ion-color-secondary);
      border: 1px solid var(--ion-color-borders);
      border-radius: var(--radius-m);
      --padding-start: 40px !important;
      --color: var(--ion-color-secondary) !important;
      --placeholder-font-weight: var(--font-semibold);
      --placeholder-color: white !important;
      --placeholder-opacity: 1 !important;
    }

    ion-input {
      width: auto !important;
      margin: 0 0 0 var(--grid-s);
    }
  }

  // Modes

  &.transparent,
  &.transparent ion-toolbar {
    --background: transparent;
    --padding-bottom: var(--grid-xl) !important;
    --border-width: 0 !important;

    &.places,
    &.places ion-toolbar {
      --background: var(--ion-gradient-places) !important;
    }

    * {
      text-shadow: var(--ion-color-textshadow);
    }

    ion-button.circle {
      --background: var(--ion-color-components) !important;
    }
  }

  // Parallax

  ion-thumbnail.parallax {
    position: absolute;
    background-color: var(--ion-color-background);
    top: 0;
    left: 0;
    height: 350px;
    width: 100%;
    z-index: -1;
    pointer-events: none;

    &.noimage {
      height: 86px !important;
    }

    cached-image,
    cached-image ion-img {
      position: relative;
      background-color: var(--ion-color-background);
      overflow: inherit !important;
      z-index: 0;
    }

    .image {
      background-color: var(--ion-color-background);
      width: 100%;
      height: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      background-color: var(--ion-color-background);
      height: 22px;
      bottom: -4px;
      left: 0;
      right: 0;
      width: 100%;
      border-radius: var(--radius-m) var(--radius-m) 0 0;
      pointer-events: none;
      z-index: 1;
    }

    .gallery {
      width: calc(100% + var(--grid-xxl));
      padding: 0 var(--grid-s);
      margin: 0 0 var(--grid-xxs) -16px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;

      .image {
        position: relative;
        display: inline-block;
        background-color: var(--ion-color-components) !important;
        background-position: center;
        background-size: cover;
        margin: 0 var(--grid-xxs) 0 0;
        width: 80px;
        height: 80px;
        text-align: center;
        border: 1px solid var(--ion-color-borders);
        border-radius: var(--radius-m) !important;
        vertical-align: middle;
        overflow: hidden;
        cursor: pointer;

        &:last-of-type {
          margin-right: 0 !important;
        }

        cached-image,
        cached-image img {
          border-radius: var(--radius-s) !important;
        }

        &.map {
          background-image: url('/assets/imgs/map-light.jpg');
          border: none;

          .icon {
            background-color: var(--ion-color-background);
            margin: calc(50% - 13px) 0 0 calc(50% - 13px);
            padding: 5px;
            width: 26px;
            height: 26px;
            border-radius: 14px;
          }
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------
// Grid
// -----------------------------------------------------------------------------

ion-grid {
  max-width: 800px !important;
  margin: 0 auto var(--grid-xxs) auto;
  padding: 0;

  &.padding {
    padding: 0 var(--grid-s);
  }

  &.border {
    margin-bottom: var(--grid-xxxs);
    padding-bottom: var(--grid-s);
    border-bottom: 1px solid var(--ion-color-borders);
  }

  &.fade {
    position: relative;
    overflow-x: auto;
    white-space: nowrap;

    @media only screen and (min-width: 620px) {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100px;
        pointer-events: none;
        background: linear-gradient(to left, rgb(248, 249, 252, 1), rgb(248, 249, 252, 0));
      }
    }
  }
}

ion-row {
  &.section {
    max-width: 500px;
    margin: 0 auto;
  }
}

// -----------------------------------------------------------------------------
// Modal
// -----------------------------------------------------------------------------

ion-modal {
  padding-top: calc(10px + var(--ion-safe-area-top));
  --ion-background-color: var(--ion-color-background) !important;
  --border-radius: var(--radius-m) !important;
  --padding-start: var(--grid-xs);
  --padding-end: var(--grid-s);
  --padding-bottom: var(--grid-xxs);
  --padding-top: var(--grid-xs);
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;

  // Handle

  &::part(handle) {
    background: var(--ion-color-tertiary);
    opacity: .3;
  }

  // Web

  @media only screen and (min-width: 768px) {
    --top: 0 !important;
    --left: 10px !important;
    --bottom: 0 !important;
    --height: auto !important;
    --width: 420px !important;
    --z-index: 1;

    &::part(content) {
      top: var(--grid-s) !important;
      left: var(--grid-s) !important;
      bottom: var(--grid-s) !important;
      box-shadow: 0 2px var(--grid-xxs) rgba(0, 0, 0, 0.2);
    }

    &.fullscreen {
      --right: 0 !important;
    }

    ion-toolbar ion-button.expand {
      display: block !important;
    }
  }

  @media only screen and (min-width: 1200px) {
    --width: 500px !important;
  }

  @media only screen and (min-width: 1500px) {
    --width: 600px !important;
  }

  &.datetime {
    --height: auto;
    --width: auto;
    --max-width: 400px;
    --min-height: auto;
    padding: var(--grid-s);
  }
}

// -----------------------------------------------------------------------------
// Content
// -----------------------------------------------------------------------------

ion-content {

  &.top {
    --padding-top: calc(260px - var(--ion-safe-area-top) + var(--offset-top)) !important;
  }

  &.noimage {
    --padding-top: 0 !important;
  }

  &.list {
    --padding-top: var(--grid-xxs) !important;
  }

  &.bottom {
    --padding-bottom: 50px !important;
  }

  &.footer {
    --padding-bottom: 130px !important;
  }

  &.sides {
    --padding-start: 0;
    --padding-end: 0;
  }

  // Accept

  .accept {
    background-color: var(--ion-color-components);
    margin: var(--grid-xs) 0 0 0;
    padding: var(--grid-m) var(--grid-s) var(--grid-m) var(--grid-s);
    text-align: center;
    border-radius: var(--radius-m);
    border: 1px solid var(--ion-color-borders);

    @media only screen and (max-width: 768px) {
      padding: var(--grid-s) var(--grid-s) var(--grid-l);
    }

    img.visual {
      margin: 0 0 var(--grid-s) 0;

      @media only screen and (max-width: 768px) {
        width: 140px;
        height: 140px;
      }
    }

    h1 {
      font-size: var(--font-xxl);
      color: var(--ion-color-secondary);

      @media only screen and (max-width: 768px) {
        font-size: var(--font-xl);
      }
    }

    h2 {
      max-width: 500px;
      margin: 0 auto;
      padding: var(--grid-xxs) 0 var(--grid-s) 0;
      font-size: 18px;
      color: var(--ion-color-primary);
    }

    .text {
      max-width: 500px;
      margin: 0 auto;
      padding: var(--grid-s);
      font-size: var(--font-s);
      color: var(--ion-color-secondary);
      line-height: 22px;
    }

    .buttons {
      padding: 0;

      a,
      button {
        margin: var(--grid-xxxs) var(--grid-xxxs) var(--grid-s) var(--grid-xxxs);
        text-decoration: none;

        &.blue {
          border: none;
        }
      }

      .text {
        padding-top: 0;
      }

      .qrcode {
        display: inline-block;
        margin: 0 var(--grid-xxs);
        padding: 0;
        border-radius: var(--radius-m);

        span {
          display: block;
          padding: var(--grid-xxs) 0 6px 0;
          font-size: var(--font-xs);
          font-weight: var(--font-bold);
          color: white;

          ion-icon {
            margin: -2px 2px 0 0;
            color: white;
            vertical-align: middle;
          }
        }

        .qr {
          background-color: white;
          padding: var(--grid-s);
          height: 160px;
          width: 160px;
          margin: 0;
          border: 3px solid var(--ion-color-secondary);
          border-radius: var(--radius-m);
        }
      }
    }

    .about {
      font-size: var(--font-xs);
      color: var(--ion-color-tertiary);
      font-weight: var(--font-semibold);
      text-decoration: none;
    }

    .assets {

      a {
        border: none;
      }

      img {
        margin: 0 var(--grid-xxs) var(--grid-xxs) 0;
        width: 160px;
        height: auto;
        border-radius: var(--radius-m);

        &.logo {
          width: auto;
          height: 120px;
        }
      }
    }
  }

  // Page

  .page {
    padding: var(--grid-m) 0 var(--grid-xxl) 0;
    color: var(--ion-color-secondary);

    h1 {
      font-size: 44px;
      font-weight: 800;
    }

    h3 {
      margin: 10px 0 0 0;
      color: var(--ion-color-primary);

      &.margin {
        margin-bottom: var(--grid-m);
      }
    }

    ion-icon.faq {
      margin: -2px 0 0 0;
      color: var(--ion-color-secondary);
      vertical-align: middle;
    }

    b {
      font-weight: var(--font-semibold);
      color: var(--ion-color-secondary);
    }

    p,
    li {
      font-size: var(--font-s);
      font-weight: 500;
      line-height: 23px;
      color: var(--ion-color-secondary);
    }

    ul {
      margin-bottom: var(--grid-m);
      padding-left: var(--grid-xxl);

      li {
        margin-bottom: 6px;
      }
    }

    a {
      color: var(--ion-color-secondary);
      border-bottom: 1px dotted var(--ion-color-secondary);
      text-decoration: none;
    }

    .intro {
      padding: 0 var(--grid-xxxl) 0 var(--grid-m);
      margin-bottom: var(--grid-xxl);
    }

    .error {
      text-align: center;
    }

    .box {
      background-color: var(--ion-color-components);
      margin: 0 0 var(--grid-m) 0;
      padding: var(--grid-m) var(--grid-xxl);
      border-radius: var(--radius-m);

      @media only screen and (max-width: 768px) {
        padding: var(--grid-xxs) var(--grid-s);
      }

      h3 {
        margin: var(--grid-s) 0;
        font-size: 18px;
      }

      .code * {
        padding-right: var(--grid-m);
        font-family: monospace;
        font-size: 13px;
        font-style: normal;
        line-height: 22px;
        opacity: .9;
      }

      .assets {

        a {
          border: none;
        }

        img {
          margin: 0 var(--grid-xxs) var(--grid-xxs) 0;
          width: 160px;
          height: auto;
          border-radius: var(--radius-m);

          &.logo {
            width: auto;
            height: 120px;
          }
        }
      }

      &.block {
        margin-top: 0;

        &.centered {
          padding: var(--grid-xxl) var(--grid-s) var(--grid-xxxl) var(--grid-s);
          text-align: center;
        }

        img.visual {
          margin: 0 0 var(--grid-s) 0;

          @media only screen and (max-width: 768px) {
            width: 140px;
            height: 140px;
          }
        }

        h1 {
          font-size: var(--font-xxl);
          color: var(--ion-color-secondary);

          @media only screen and (max-width: 768px) {
            font-size: var(--font-xl);
          }
        }

        .text {
          max-width: 500px;
          margin: 0 auto;
          padding: var(--grid-s);
          font-size: var(--font-s);
          color: var(--ion-color-secondary);
          line-height: 22px;
        }

        .buttons {
          padding: var(--grid-s) 0 var(--grid-xxxs) 0;

          a,
          button {
            margin: var(--grid-xxxs) var(--grid-xxxs) var(--grid-s) var(--grid-xxxs);


            &.blue {
              border: none;
            }
          }

          .text {
            padding-top: 0;
          }

          .qrcode {
            display: inline-block;
            margin: 0 var(--grid-xxs);
            padding: 0;
            border-radius: var(--radius-m);

            span {
              display: block;
              padding: var(--grid-xxs) 0 6px 0;
              font-size: var(--font-xs);
              font-weight: var(--font-bold);
              color: white;

              ion-icon {
                margin: -2px 2px 0 0;
                color: white;
                vertical-align: middle;
              }
            }

            .qr {
              background-color: white;
              padding: var(--grid-s);
              height: 160px;
              width: 160px;
              margin: 0;
              border: 3px solid var(--ion-color-secondary);
              border-radius: var(--radius-m);
            }
          }
        }
      }
    }
  }

  .viewer {
    background-color: var(--ion-color-background);
    width: calc(100% + var(--grid-xxl));
    margin: -15px 0 var(--grid-xs) -16px;
    padding: var(--grid-s) var(--grid-s) var(--grid-m) var(--grid-s);
    border-bottom: 1px solid var(--ion-color-borders);
  }

  // OpenAI

  .openai {
    background-color: var(--ion-color-components);
    width: 100%;
    margin: 0 auto var(--grid-s);
    padding: var(--grid-s);
    border: 1px solid var(--ion-color-borders);
    border-radius: var(--radius-m);

    .title {
      margin: 0 0 6px 0;
      font-size: 18px;
      font-weight: var(--font-bold);
    }

    .cta {
      margin-top: 10px;
    }
  }

  // Types

  .section {
    position: relative;
    display: table;
    background-color: var(--ion-color-components);
    padding: var(--grid-m);
    margin: 0 auto var(--grid-s) auto;
    width: 100%;
    max-width: 584px;
    text-align: left;
    border-radius: var(--radius-m);
    border: 1px solid var(--ion-color-borders);
    box-shadow: none;

    .header {
      margin: 0 0 var(--grid-xs) 0;
      padding: 0;
      font-size: var(--font-xl);
      font-weight: var(--font-bold);
      color: var(--ion-color-primary);
    }

    .subheader {
      margin: 0 0 var(--grid-xxs);
      font-size: var(--font-s);
      font-weight: var(--font-bold);

      &.primary {
        color: var(--ion-color-primary);
      }
    }

    ul {
      margin: 0 0 var(--grid-s);
      padding: var(--grid-xxxs) 0;

      li {
        margin: 0;
        padding: 6px 0;
        list-style-type: none;
        font-size: 13px;

        ion-icon {
          margin: -2px var(--grid-xxxs) 0 0;
          width: var(--grid-m);
          height: var(--grid-m);
          vertical-align: middle;
        }

        &.primary {
          color: var(--ion-color-primary);
          font-weight: var(--font-semibold);
        }
      }
    }

    ion-list-header {
      background-color: var(--ion-color-background) !important;
      margin-bottom: var(--grid-xs) !important;
      border-radius: var(--radius-m) !important;
      border: 1px solid var(--ion-color-borders) !important;
      pointer-events: none;
    }

    ion-item,
    ion-card {
      --background: var(--ion-color-background) !important;
      background-color: var(--ion-color-background) !important;
      margin-bottom: 0 !important;
      border-radius: var(--radius-m) !important;
      border: 1px solid var(--ion-color-borders) !important;
      pointer-events: none;
    }
  }

  // Placeholder

  .placeholder {
    position: relative;
    display: table;
    background-color: var(--ion-color-components);
    margin: 0 0 var(--grid-xs) 0;
    width: 100%;
    padding: 40px 60px;
    text-align: center;
    border-radius: var(--radius-m);

    .inner {
      display: table-cell;
      vertical-align: middle;

      .title {
        margin: 0 0 15px 0;
        font-size: 15px;
        font-weight: var(--font-semibold);
        line-height: var(--grid-l);
        white-space: normal;
        color: var(--ion-color-secondary);
      }

      .cta {
        padding: var(--grid-xxs) var(--grid-s);
        border-radius: var(--radius-m);
      }
    }
  }

  // Empty

  .empty {
    background-color: var(--ion-color-components);
    padding: var(--grid-s) var(--grid-s);
    margin: 0 var(--grid-m) var(--grid-xxxs) var(--grid-m);
    min-height: var(--size-element);
    font-size: var(--font-xs);
    font-weight: var(--font-semibold);
    color: var(--ion-color-tertiary);
    border: 1px solid var(--ion-color-borders);
    border-radius: var(--radius-m);
  }

  // Scroll

  .scroll-horizontal {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: calc(100% + var(--grid-xxl));
    margin: 0 0 0 -16px;
    padding: 0 var(--grid-s);

    card {
      display: inline-block;
    }
  }
}

// -----------------------------------------------------------------------------
// List
// -----------------------------------------------------------------------------

ion-list {
  background: inherit !important;
  margin: 0 auto !important;
  padding: 0 0 !important;
  position: relative;

  &.padding {
    padding: 0 var(--grid-m) !important;
  }

  &.grid {
    position: relative;
    display: inline-grid;
    padding: 0 var(--grid-m) !important;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--grid-s);
    width: 100%;
  }

  &.items {
    padding: 0 !important;
  }

  .reorder-selected {
    background-color: transparent;
    border-radius: var(--radius-m);
    box-shadow: none;
  }

  &.group {
    background: var(--ion-color-components) !important;
    margin: 0 var(--grid-s) var(--grid-xxs) var(--grid-s) !important;
    padding: 0 !important;
    border: 1px solid var(--ion-color-borders);
    box-shadow: var(--ion-color-shadow);
    border-radius: var(--radius-m);

    app-item:last-child {
      ion-item {
        border-bottom: 0 !important;
      }
    }
  }

  // Scroll

  &.scroll {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 0 var(--grid-xs) 0;
    padding: 0 var(--grid-s) 0 var(--grid-s);
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    app-card {
      display: inline-block;
      width: 135px;
      height: 160px;
      margin-right: var(--grid-xs);
    }
  }

  &.section {
    max-width: 500px;
    margin: 0 auto;
  }

  &.padding-sides {
    padding: 0 var(--grid-s);
  }
}

// -----------------------------------------------------------------------------
// Listheader
// -----------------------------------------------------------------------------

ion-list-header {
  background-color: transparent;
  margin: 0 auto var(--grid-xxxs) auto !important;
  padding: 0 var(--grid-m) !important;
  height: var(--radius-l) !important;
  font-size: 15px;
  font-weight: var(--font-semibold);
  line-height: var(--radius-l) !important;
  color: var(--ion-color-tertiary);
  text-transform: inherit;
  border: none !important;

  ion-row {
    position: absolute;
    padding-right: var(--grid-xxxs);
    margin: 0;
    top: 0;
    right: 0;

    ion-col {
      height: var(--radius-l);

      ion-icon {
        margin: -10px 0 0 var(--grid-xxs);
        height: 18px;
        width: 18px;
        line-height: 0;
        cursor: pointer;
        color: var(--ion-color-tertiary);

        &.more {
          height: var(--grid-m);
          width: var(--grid-m);
        }

        &.display {
          height: 17px;
          width: 17px;
        }

        &.blue {
          margin-top: var(--grid-xxs);
          height: var(--grid-m);
          width: var(--grid-m);
        }
      }
    }
  }

  &.small {
    padding: 0 var(--grid-xs) 0 var(--grid-m) !important;
    font-size: var(--font-xs) !important;
    font-weight: 600 !important;
    color: var(--ion-color-tertiary) !important;

    ion-row ion-icon {
      height: var(--grid-s) !important;
      width: var(--grid-s) !important;
    }
  }

  &.reorder {
    padding: 0 var(--grid-xs) !important;
  }

  ion-icon.add {
    margin-right: var(--grid-xs);
  }

  &.padding {
    padding: 0 15px !important;
  }

  &.accordion {
    padding-left: var(--grid-s) !important;
  }

  &.nomargin {
    margin: 0 !important;
  }

  ion-label {
    margin: 0;
    font-size: var(--font-xs);
    font-weight: var(--font-semibold);
    line-height: 50px;
    color: var(--ion-color-tertiary);

    &.red {
      color: var(--ion-color-danger);
    }
  }

  ion-button {
    --padding-start: 0;
    --padding-end: 0;
    margin-right: -2px;
    margin-bottom: 10px;
  }
}

// -----------------------------------------------------------------------------
// Image
// -----------------------------------------------------------------------------

ion-img {
  object-fit: cover;
}

// -----------------------------------------------------------------------------
// Skeleton
// -----------------------------------------------------------------------------

ion-skeleton-text {
  //background-color: var(--ion-color-components) !important;
  --border-radius: var(--radius-m);
  margin: 0 var(--grid-m) var(--grid-s) var(--grid-m);
  width: auto;
  height: var(--radius-l);
  opacity: .6;

  &.default {
    height: 150px;
  }

  &.card {
    height: 220px;
    width: 200px;
  }

  &.list {
    height: var(--size-element);
  }

  &.premium {
    background-color: #433DA4 !important;
    height: 150px;
  }

  &.assistant {
    background-color: #C97B14 !important;
    height: 250px;
  }

  &.text {
    display: inline-block;
    margin: 0 0 3px 0;
    height: var(--grid-xs);
    width: 30px;
  }

  &.button {
    display: inline-block;
    margin: 0;
    height: var(--radius-l);
    width: 150px;
  }

  &.title {
    display: inline-block;
    margin: 0;
    height: var(--grid-m);
    width: 150px;
  }

  &.subtitle {
    display: inline-block;
    height: var(--grid-xs);
    width: 80px;
  }
}

// -----------------------------------------------------------------------------
// Spinner
// -----------------------------------------------------------------------------

ion-spinner {
  --color: var(--ion-color-primary);
  margin: 0 10px;
  width: var(--grid-m);
  height: var(--grid-m);

  &.white {
    --color: white;
  }
}

// -----------------------------------------------------------------------------
// Toast
// -----------------------------------------------------------------------------

ion-toast {
  --background: var(--ion-color-components);
  --color: var(--ion-color-secondary);
  --box-shadow: var(--ion-color-shadow);
  --border-radius: var(--radius-m) !important;
  --border: 1px solid var(--ion-color-borders) !important;

  &::part(button) {
    font-size: 22px;
    color: white;
  }
}

// -----------------------------------------------------------------------------
// Icon
// -----------------------------------------------------------------------------

ion-icon {
  --ionicon-stroke-width: 50px;
  color: var(--ion-color-tertiary);

  &.big {
    width: 40px;
    height: 40px;
    margin: 10px 0 var(--grid-m);
  }

  &.shadow {
    -webkit-filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, .3));
    filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, .3));
  }

  &.thick {
    --ionicon-stroke-width: 70px !important;
  }

  &.dark {
    color: var(--ion-color-secondary);
  }

  &.blue,
  &.purple {
    color: var(--ion-color-primary);
  }

  &.red {
    color: var(--ion-color-danger) !important;
  }

  &.green {
    color: var(--ion-color-success) !important;
  }

  &.orange {
    color: var(--ion-color-warning) !important;
  }

  &.white {
    color: white !important;
  }
}

// -----------------------------------------------------------------------------
// Chips
// -----------------------------------------------------------------------------

ion-chip {
  display: inline-block;
  background-color: var(--ion-color-background);
  margin: 0 var(--grid-xxs) var(--grid-xxs) 0;
  padding: var(--grid-xxs) 10px var(--grid-xxs) var(--grid-xxs);
  height: var(--grid-xxl);
  font-size: var(--font-xs);
  line-height: 14px;
  font-weight: var(--font-semibold);
  color: var(--ion-color-secondary);
  border-radius: var(--grid-m);
  border: 1px solid var(--ion-color-borders);
  cursor: inherit;

  ion-icon {
    display: inline-block;
    margin: -2px 5px 0 0;
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }

  &.link {
    cursor: pointer;
  }

  &.blue {
    color: var(--ion-color-primary);

    ion-icon {
      margin-right: 3px;
      color: var(--ion-color-primary);
    }
  }

  .avatar {
    display: inline-block;
    background-color: var(--ion-color-tertiary);
    background-size: cover;
    margin: -4px 6px 0 -3px !important;
    width: var(--radius-m);
    height: var(--radius-m);
    vertical-align: middle;
    border-radius: var(--radius-s);
  }
}

// -----------------------------------------------------------------------------
// Action
// -----------------------------------------------------------------------------

ion-action-sheet {
  --background: var(--ion-color-components) !important;
  --button-background: var(--ion-color-background) !important;

  .action-sheet-container {
    padding: 0 !important;
  }

  .action-sheet-wrapper {
    margin-bottom: 0 !important;
  }

  .action-sheet-group {
    margin: var(--grid-xxs) !important;
    padding-bottom: 0 !important;
    background-color: var(--ion-color-background) !important;
    border-radius: var(--radius-m) !important;

    &.action-sheet-group-cancel {
      border-radius: 0 !important;
    }

    .action-sheet-title {
      padding-top: var(--grid-s) !important;
      padding-bottom: var(--grid-s) !important;
      padding-left: var(--grid-m) !important;
      padding-right: var(--grid-m) !important;
      font-size: var(--font-xs);
      font-weight: var(--font-semibold);
      text-align: left;
      color: var(--ion-color-tertiary);
      border-bottom: 1px solid var(--ion-color-background);
    }

    .action-sheet-button {
      background: var(--ion-color-background) !important;
      padding-top: var(--grid-xxs) !important;
      padding-bottom: var(--grid-xxs) !important;
      padding-left: var(--grid-m) !important;
      padding-right: var(--grid-m) !important;
      font-size: var(--font-s);
      font-weight: var(--font-semibold);
      text-align: left;
      color: var(--ion-color-secondary);
      border-bottom: 1px solid var(--ion-color-borders);

      &.unselected {
        color: var(--ion-color-tertiary);
      }

      &.action-sheet-icon {
        background-repeat: no-repeat !important;
        background-position: 19px !important;
        background-size: 18px !important;
        padding-left: 50px !important;

        &.edit {
          background-image: url('/assets/icons/color/edit-05.svg') !important;
        }

        &.status {
          background-image: url('/assets/icons/color/check-square-broken.svg') !important;
        }

        &.rename {
          background-image: url('/assets/icons/color/edit-04.svg') !important;
        }

        &.invite {
          background-image: url('/assets/icons/color/user-plus-01.svg') !important;
          color: var(--ion-color-primary);
        }

        &.profile {
          background-image: url('/assets/icons/color/user-square.svg') !important;
        }

        &.move {
          background-image: url('/assets/icons/color/folder-plus.svg') !important;
        }

        &.share {
          background-image: url('/assets/icons/color/share-06.svg') !important;
        }

        &.follow {
          background-image: url('/assets/icons/color/user-check-01.svg') !important;
        }

        &.unfollow {
          background-image: url('/assets/icons/color/user-minus-01.svg') !important;
          color: var(--ion-color-danger);
        }

        &.assistant {
          background-image: url('/assets/icons/color/stars-02.svg') !important;
          color: var(--ion-color-warning);
        }

        &.delete {
          background-image: url('/assets/icons/color/trash-01.svg') !important;
        }

        &.show {
          background-image: url('/assets/icons/color/eye.svg') !important;
        }

        &.hide {
          background-image: url('/assets/icons/color/eye-off.svg') !important;
        }

        &.selected {
          background-image: url('/assets/icons/color/check-circle-selected.svg') !important;
        }

        &.unselected {
          background-image: url('/assets/icons/color/circle.svg') !important;
        }

        &.search {
          background-image: url('/assets/icons/color/search-refraction.svg') !important;
        }

        &.library {
          background-image: url('/assets/icons/color/image-plus.svg') !important;
        }

        &.camera {
          background-image: url('/assets/icons/color/camera-01.svg') !important;
        }

      }

      .action-sheet-button-inner {
        justify-content: left;

        .action-sheet-icon {
          font-size: var(--font-l);
          color: var(--ion-color-tertiary);
          margin-right: var(--grid-xs);
        }
      }

      &.line {

        &.top {
          border-top: 5px solid var(--ion-color-borders);
        }

        &.bottom {
          border-bottom: 5px solid var(--ion-color-borders);
        }
      }

      &:last-child {
        border-bottom: 0 !important;
      }

      &.ion-focused,
      &.selected,
      &::after {
        background-color: var(--ion-color-background) !important;
      }

      &.primary,
      &.selected,
      &.primary .action-sheet-icon,
      &.selected .action-sheet-icon {
        color: var(--ion-color-secondary);
        font-weight: var(--font-semibold);
      }

      &.warning,
      &.warning .action-sheet-icon {
        color: var(--ion-color-warning);
      }

      &.success,
      &.success .action-sheet-icon {
        color: var(--ion-color-success);
      }

      &.action-sheet-destructive,
      &.action-sheet-destructive .action-sheet-icon {
        color: var(--ion-color-danger);
      }
    }

    .action-sheet-cancel {
      color: var(--ion-color-tertiary);
    }
  }
}

// -----------------------------------------------------------------------------
// Segment
// -----------------------------------------------------------------------------

ion-segment {
  width: 100%;
  border: none;
  --background: var(--ion-color-background);
  --border-radius: 0 !important;

  ion-segment-button {
    margin: 0;
    min-width: 40px !important;
    font-size: var(--font-s);
    font-weight: var(--font-semibold);
    border-bottom: 1px solid var(--ion-color-borders);
    --background-focused: var(--ion-color-background);
    --indicator-color: var(--ion-color-secondary);
    --indicator-height: 1px;
    --color: var(--ion-color-secondary);
    --color-checked: var(--ion-color-secondary);
    --color-focused: var(--ion-color-secondary) !important;
    --border-radius: 0 !important;
    --ripple-color: transparent;

    &::part(native) {
      border-radius: 0 !important;
    }

    &:first-of-type {
      border-radius: 0 !important;
    }

    ion-label {
      font-size: var(--font-s);
      text-transform: capitalize;
      letter-spacing: normal;
    }

    .badge {
      display: inline-block;
      background-color: var(--ion-color-components);
      padding: 2px var(--grid-xxs);
      margin: 0 0 0 var(--grid-xxxs);
      border-radius: var(--radius-m);

      span {
        display: inline-block;
        margin: 0 1px;
        font-size: var(--font-xs);
        font-weight: var(--font-bold);
        opacity: .5;

        &.number {
          font-weight: 800;
          color: var(--ion-color-success);
          opacity: 1;
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------
// Alert
// -----------------------------------------------------------------------------

ion-alert {

  h2 {
    margin: 5px 0 0 0 !important;
    color: var(--ion-color-secondary) !important;
    font-weight: 700 !important;
  }

  h3 {
    line-height: var(--grid-m);
    letter-spacing: .1px;
    font-weight: 500 !important;
  }

  .alert-message {
    color: var(--ion-color-secondary) !important;
    padding-left: var(--grid-xxl) !important;
    padding-right: var(--grid-xxl) !important;
    font-size: var(--font-xs) !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    opacity: .75;
  }

  .alert-radio-label {
    font-weight: var(--font-semibold);
    color: var(--ion-color-secondary) !important;
  }

  .alert-input {
    margin-top: 0 !important;
    padding: var(--grid-xs) var(--grid-xs) !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: var(--ion-color-secondary) !important;
    border-radius: 11px !important;
    border: 1px solid var(--ion-color-borders) !important;
    border-color: var(--ion-color-borders) !important;
  }

  .alert-input-group {
    .alert-input-wrapper {
      margin-bottom: var(--grid-xxs);
    }
  }

  textarea {
    height: 120px !important;
  }

  .alert-button.sc-ion-alert-ios {
    color: var(--ion-color-tertiary);
    font-size: 15px;
    font-weight: var(--font-semibold);

    &:last-child {
      border-right: none;
    }

    &.submit,
    &:last-child {
      color: var(--ion-color-primary) !important;
    }

    &.delete {
      color: var(--ion-color-danger) !important;
    }
  }
}

// -----------------------------------------------------------------------------
// Popover
// -----------------------------------------------------------------------------

ion-popover {
  //--width: calc(100% - 10px - var(--grid-xxl)) !important;
  //--height: 50% !important;
  //--max-width: 576px;
  --offset-x: var(--grid-s);
  --backdrop-opacity: 0.5;
  --background: var(--ion-color-background);
  --border-radius: var(--radius-m) !important;
  border-radius: var(--radius-m) !important;

  &::part(content) {
    border-radius: var(--radius-m) !important;
  }

  ion-item {
    --background: var(--ion-color-components);
    --padding-top: var(--grid-xxxs);
    --padding-end: 0;
    --padding-start: var(--grid-s);
    --padding-bottom: var(--grid-xxxs);
    border-bottom: 1px solid var(--ion-color-borders);

    &.active {
      color: var(--ion-color-primary);

      ion-icon.check,
      ion-icon.selected {
        display: block;
      }

      ion-icon.default {
        display: none;
      }
    }

    ion-icon {
      width: var(--grid-m);
      height: var(--grid-m);

      &.default,
      &.selected {
        margin: 0 var(--grid-xs) 0 0;
      }

      &.selected,
      &.check {
        display: none;
      }
    }

    ion-label {
      width: 100%;
      font-size: var(--font-s);
      font-weight: var(--font-semibold);
    }
  }
}

// -----------------------------------------------------------------------------
// Popover
// -----------------------------------------------------------------------------

ion-accordion-group {
  margin: 0;
  box-shadow: none;

  ion-accordion {
    background: transparent;
    margin-bottom: var(--grid-xxxs);

    .more {
      cursor: pointer;
    }

    ion-item {
      --background: var(--ion-color-components);
      --inner-padding-top: 0;
      --inner-padding-bottom: 0;
      --inner-padding-start: var(--grid-xs);
      --inner-padding-end: var(--grid-xs);
      --color: var(--ion-color-secondary);
      font-size: var(--font-s);
      font-weight: var(--font-semibold);
      border: 1px solid var(--ion-color-borders);
      box-shadow: none;

      &.outline {
        --background: transparent;
      }

      &:hover {
        --color: var(--ion-color-secondary) !important;
      }
    }

    ion-icon {
      margin: -2px 0 0 0;
      color: var(--ion-color-secondary);
      vertical-align: middle;
    }

    .answer {
      padding: var(--grid-xxxs) var(--grid-s) var(--grid-l) var(--grid-s);
      font-size: var(--font-s);
      line-height: var(--grid-m);
      color: var(--ion-color-secondary) !important;
    }

    &.accordion-expanding ion-item,
    &.accordion-expanded ion-item {
      --color: var(--ion-color-primary) !important;
    }
  }
}

// -----------------------------------------------------------------------------
// Refresher
// -----------------------------------------------------------------------------

ion-refresher {
  z-index: 0 !important;

  ion-spinner {
    color: var(--ion-color-secondary);
  }
}