// -----------------------------------------------------------------------------
// Body
// -----------------------------------------------------------------------------

body,
html,
ion-content {
  background-color: var(--ion-color-background);
  --background: var(--ion-color-background);
  font-family: var(--font-family);
}

// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------

::placeholder {
  color: var(--ion-color-tertiary) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 var(--grid-m);
  padding: 0;
  font-weight: var(--font-bold);
}

h1 {
  padding: 0;
  margin: 0;
  color: var(--ion-color-secondary);
}

// -----------------------------------------------------------------------------
// Dark
// -----------------------------------------------------------------------------

html.ion-palette-dark {

  ion-content .mapbox .overlay {
    background-color: rgba(0, 0, 0, 0.6) !important;
  }

  .gallery .image.map {
    background-image: url('/assets/imgs/map-dark.jpg') !important;
  }
}

// -----------------------------------------------------------------------------
// Capacitor
// -----------------------------------------------------------------------------

.plt-capacitor {

  ion-header {
    width: 100% !important;
    max-width: inherit !important;
  }

  ion-footer.tabbar {
    top: inherit !important;
    bottom: 0 !important;
    border-radius: var(--grid-xxl) var(--grid-xxl) 0 0 !important;
    border-top: 1px solid var(--ion-color-borders) !important;
  }

  // Android

  &.plt-android {

    ion-modal {
      --max-height: 94% !important;
    }

    modal-home ion-header.safearea {
      padding-top: calc(var(--grid-xxl) + var(--grid-xxs));
    }

    #buttons {
      padding-top: var(--grid-m) !important;
    }
  }

  // iOS

  &.plt-ios {

    ion-header .parallax {
      &.noimage {
        height: calc(96px + var(--ion-statusbar-padding)) !important;
      }
    }

    ion-content.view {
      @media (max-width:719px) {
        --padding-top: calc(275px - var(--ion-safe-area-top) + var(--offset-top)) !important;
      }

      &.noimage {
        --padding-top: var(--ion-statusbar-padding) !important;
      }
    }
  }

  ion-toolbar:first-child {
    padding-top: var(--ion-safe-area-top) !important;
  }

  ion-modal {
    --border-radius: var(--radius-m) !important;

    ion-toolbar:first-child {
      padding-top: 0 !important;
    }
  }
}

// -----------------------------------------------------------------------------
// PWA
// -----------------------------------------------------------------------------

.plt-pwa {
  &.plt-ios {
    @media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
      ion-tab-bar {
        padding-bottom: 34px !important;
      }
    }
  }
}

// -----------------------------------------------------------------------------
// Elements
// -----------------------------------------------------------------------------

*::-webkit-scrollbar,
::-webkit-scrollbar {
  display: none !important;
}

* {
  outline: none;
}

img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}

.modal-handle {
  background: var(--ion-color-borders) !important;
}

ion-app [padding] .scroll-content {
  padding: var(--grid-m);
}

page-login {
  @media only screen and (min-width: 1200px) {
    margin: auto;
    max-width: 1200px;
    max-height: 900px;
    border-radius: var(--radius-m);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
}

ol {
  padding: 0;

  ol {
    padding: 10px 0 0 30px;

    li {
      padding: 0 0 10px;
    }
  }
}

html ion-modal.modal-card .ion-page>ion-header>ion-toolbar:first-of-type {
  padding-top: 15px !important;
}

ion-modal.modal-default:not(.overlay-hidden)~ion-modal.modal-default {
  --backdrop-opacity: 0.3;
}

.text.premium span {
  font-weight: var(--font-bold);
  color: var(--ion-color-primary);
}

// Modals

.wide-modal ion-modal {
  --max-width: none;

  &::part(content) {
    --width: calc(100% - var(--grid-xl));
    right: var(--grid-s) !important;
  }
}

ion-modal#gallery {
  --width: calc(100% - var(--grid-xxl)) !important;
  --max-width: 600px !important;
  --height: 380px !important;
  --border-radius: var(--radius-m) !important;
  --box-shadow: 0 var(--grid-xl) 48px rgba(0, 0, 0, 0.4);

  @media only screen and (min-width: 768px) {
    --height: 600px !important;
  }

  ion-img {
    height: 100%;
  }

  &.reel {
    --height: calc(100vh - var(--grid-xxxl));
    --max-height: 655px;
  }

  iframe {
    width: 100%;
    height: 100%;

  }

  .fullscreen-img {
    position: absolute;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .fullscreen-close {
      position: absolute;
      background-color: var(--ion-color-dark);
      padding: 5px;
      top: var(--grid-m);
      right: var(--grid-m);
      width: var(--grid-xxl);
      height: var(--grid-xxl);
      line-height: var(--grid-xxl);
      text-align: center;
      border-radius: var(--grid-s);
      opacity: .8;

      ion-icon {
        width: var(--radius-m);
        height: var(--radius-m);
        color: white;
        --ionicon-stroke-width: 70px;
      }
    }
  }
}

.modal-shadow.sc-ion-modal-md,
.modal-wrapper.sc-ion-modal-md {
  border-radius: var(--grid-m);
}

.clear {
  clear: both;
}

.paddingbottom {
  width: 100%;
  height: 120px;
}

.line {
  background-color: var(--ion-color-borders);
  width: 100%;
  height: 1px;
}

.has-refresher>.scroll-content {
  border-top: none;
}

.refresher-pulling-icon,
.refresher-refreshing-icon {
  color: var(--ion-color-tertiary);
}

.platform-core .modal-wrapper {
  border-radius: var(--radius-m);

  ion-header,
  ion-toolbar {
    height: 60px !important;
  }
}

// -----------------------------------------------------------------------------
// Animations
// -----------------------------------------------------------------------------

@keyframes pulseBlue {
  0% {
    box-shadow: 0 0 0 0 rgba(83, 100, 255, 0.8);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(83, 100, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(83, 100, 255, 0);
  }
}

@keyframes pulseGreen {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 190, 144, 0.5);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(83, 100, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(83, 100, 255, 0);
  }
}

@keyframes pulseRed {
  0% {
    box-shadow: 0 0 0 0 rgba(219, 99, 126, 0.5);
  }

  70% {
    box-shadow: 0 0 0 5px rgba(83, 100, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(83, 100, 255, 0);
  }
}

@keyframes pulseOrange {
  0% {
    box-shadow: 0 0 0 0 rgba(247, 147, 13, 0.5);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(83, 100, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(83, 100, 255, 0);
  }
}

@keyframes pulseWhite {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes bounceBox {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes bounceBoxVertical {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-10px);
  }
}

@keyframes shrinkImage {
  0% {
    background-size: auto 100%;
  }

  100% {
    background-size: auto 120%;
  }
}

@keyframes showFireworks {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 -2px var(--ion-color-success), 0 0 0 -2px var(--ion-color-success), 0 0 0 -2px var(--ion-color-success), 0 0 0 -2px var(--ion-color-success), 0 0 0 -2px var(--ion-color-success), 0 0 0 -2px var(--ion-color-success);
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    box-shadow: 0 -24px 0 0px var(--ion-color-success), 19px -13px 0 0px var(--ion-color-success), 19px 13px 0 0px var(--ion-color-success), 0 var(--grid-l) 0 0px var(--ion-color-success), -19px 13px 0 0px var(--ion-color-success), -19px -13px 0 0px var(--ion-color-success);
  }
}