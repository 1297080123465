// -----------------------------------------------------------------------------
// Input
// -----------------------------------------------------------------------------

ion-input {
  background-color: var(--ion-color-components);
  height: var(--radius-l);
  width: 100%;
  --padding-top: var(--grid-s);
  --padding-start: var(--grid-s);
  --padding-end: var(--grid-s);
  --padding-bottom: var(--grid-s);
  margin: 0 0 var(--grid-s);
  color: var(--ion-color-secondary);
  font-size: var(--font-s);
  font-weight: var(--font-bold);
  text-align: center;
  border: none;
  border: 1px solid var(--ion-color-borders);
  border-radius: var(--radius-m);
  --color: var(--ion-color-secondary) !important;
  --placeholder-font-weight: var(--font-semibold);
  --placeholder-color: white !important;
  --placeholder-opacity: 1 !important;

  &.left {
    --padding-start: var(--grid-s);
    --padding-end: var(--grid-s);
    text-align: left;
    font-size: var(--font-s);
  }

  &.searchbar {
    background-color: var(--ion-color-components) !important;
    background-image: url("/assets/icons/color/search-refraction.svg") !important;
    background-repeat: no-repeat !important;
    background-position: var(--grid-xs) var(--grid-xs) !important;
    background-size: 18px !important;
    width: calc(100% - var(--radius-m));
    max-width: 680px;
    height: var(--radius-l) !important;
    text-align: left;
    padding: 0 var(--grid-m) 0 40px;
    font-size: var(--font-s);
    font-weight: var(--font-semibold);
    color: var(--ion-color-secondary);
    border: 1px solid var(--ion-color-borders);
    border-radius: var(--radius-m);
    --padding-start: 40px !important;
    margin: 0 0 0 var(--grid-xxs);

    @media (min-width: 768px) {
      margin: 0 auto;
    }

    .input-clear-icon ion-icon {
      color: var(--ion-color-secondary);
    }
  }
}

// -----------------------------------------------------------------------------
// Text
// -----------------------------------------------------------------------------

ion-text {
  --placeholder-color: var(--ion-color-tertiary) !important;
  --placeholder-opacity: 1 !important;
}

// -----------------------------------------------------------------------------
// Textarea
// -----------------------------------------------------------------------------

ion-textarea {
  background: var(--ion-color-components);
  padding: var(--grid-m) var(--grid-s) 0 var(--grid-m);
  border-radius: var(--radius-m);
  width: 100%;
  --placeholder-color: var(--ion-color-tertiary) !important;
  --placeholder-opacity: 1 !important;
  --color: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary);
  border: 1px solid var(--ion-color-borders);
  box-shadow: var(--ion-color-shadow);
  font-weight: var(--font-semibold);

  &.title {
    padding: var(--grid-xxs) var(--grid-m);
    font-size: var(--font-xl);
    font-weight: 800;
    line-height: var(--grid-xxl);
    color: var(--ion-color-secondary);
  }

  &.text {
    padding: var(--grid-xxs) var(--grid-m) 0 var(--grid-m);
    font-size: var(--font-s);
    font-weight: 500;
    line-height: 26px;
    color: var(--ion-color-secondary);
  }
}

// -----------------------------------------------------------------------------
// Toggle
// -----------------------------------------------------------------------------

ion-toggle {
  --background: var(--ion-color-tertiary);
  --track-background: var(--ion-color-tertiary);
  --track-background-checked: var(--ion-color-primary);
  --handle-box-shadow: none;
}

// -----------------------------------------------------------------------------
// DateTime
// -----------------------------------------------------------------------------

ion-datetime {
  --background: var(--ion-color-background);
  border-radius: var(--radius-m);
  min-height: 500px;

  &::part(calendar-day) {
    color: var(--ion-color-secondary);
    font-weight: 500;
    font-size: var(--font-m);
    padding: var(--grid-m);
  }

  &::part(calendar-day today) {
    font-weight: var(--font-semibold);
  }

  &::part(calendar-day active) {
    background-color: var(--ion-color-primary);
    font-weight: var(--font-bold);
    color: white;
  }

  &::part(calendar-day):focus {
    background-color: var(--ion-color-primary);
    font-weight: var(--font-bold);
    color: white;
  }

  ion-buttons {

    &.actions {
      padding: var(--grid-xxxs) 0 var(--grid-xxs) 0;

      ion-button {
        padding: var(--grid-xxxs) var(--grid-xxs);
        width: 100%;
        font-size: var(--font-s);
        font-weight: var(--font-semibold);
      }
    }

    &.submit {
      border-top: 1px solid var(--ion-color-borders);
      padding: var(--grid-xs) 0 var(--grid-xxs);

      ion-button {
        background-color: var(--ion-color-components);
        margin: var(--grid-xxxs) !important;
        padding: var(--grid-xxxs) var(--grid-s);
        width: 100%;
        font-size: var(--font-m);
        font-weight: var(--font-semibold);
        border-radius: var(--radius-m);
        border: 1px solid var(--ion-color-borders);
      }
    }
  }
}

// -----------------------------------------------------------------------------
// Checkbox
// -----------------------------------------------------------------------------

ion-checkbox {
  --background: var(--ion-color-components);
  --background-checked: var(--ion-color-success);
  --border-color: var(--ion-color-components);
  --border-color-checked: var(--ion-color-success);
  --border-radius: 10px;
  width: var(--grid-m);
  height: var(--grid-m);
}

// -----------------------------------------------------------------------------
// Select
// -----------------------------------------------------------------------------

ion-select {
  font-weight: var(--font-semibold);
  font-size: var(--font-s);
  color: var(--ion-color-secondary) !important;

  &::part(icon),
  &::part(text) {
    font-size: var(--font-xs);
    font-weight: var(--font-semibold);
    color: var(--ion-color-tertiary);
  }
}

// -----------------------------------------------------------------------------
// Searchbar
// -----------------------------------------------------------------------------

ion-searchbar {
  --background: var(--ion-color-components);
  --border-radius: var(--radius-m);
  height: var(--radius-l);
  box-shadow: none;
}

// -----------------------------------------------------------------------------
// Buttons
// ------------------------------------------------------§-----------------------

a[ion-button] {
  display: inline-block;
  cursor: pointer;
}

a[ion-button],
button {

  &.options,
  &.share {
    background-size: cover;
    background-color: inherit;
    height: var(--grid-xxl);
    width: var(--grid-xxl);
    border-radius: var(--grid-s);
  }

  &.cta {
    position: relative;
    font-weight: var(--font-bold);

    ion-icon {
      width: 18px;
      height: 18px;
      margin: -2px 2px 0 0;
      vertical-align: middle;
    }

    ion-spinner {
      vertical-align: middle;
    }

    span.right {
      display: block;
      position: absolute;
      background-color: white;
      top: var(--grid-xxs);
      right: var(--grid-xxs);
      bottom: var(--grid-xxs);
      padding: 0 var(--grid-s);
      font-size: var(--font-m);
      font-weight: 800;
      line-height: 34px;
      color: var(--ion-color-warning);
      border-radius: var(--radius-m);

      ion-icon {
        margin: -2px var(--grid-xxxs) 0 0;
        color: var(--ion-color-warning);
        vertical-align: middle;
      }
    }

    &.uppercase {
      text-transform: uppercase;
    }

    &[disabled] {
      opacity: .2;
    }


    &.small {
      height: var(--grid-xxl);
      min-width: var(--grid-xxl);
      padding: 0 var(--grid-xs);
      font-size: var(--font-xs);
      line-height: 30px;
      border-radius: var(--grid-s);

      ion-icon {
        margin: -2px 2px 0 0;
        height: var(--grid-s);
        width: var(--grid-s);
      }

      span {
        font-size: var(--font-xs);
      }

      &.circle {
        width: var(--grid-xxl);
        padding: 0;
        text-align: center;

        ion-icon {
          margin: 0;
        }
      }
    }

    &.medium {
      height: var(--size-element);
      padding: 0 var(--grid-s);
      font-size: var(--font-s);
      line-height: 42px;
      border-radius: var(--radius-m);

      &.circle {
        padding: 0;
        width: var(--size-element);
        height: var(--size-element);
        text-align: center;

        ion-icon {
          margin: -2px 0 0 0;
        }
      }
    }

    &.default {
      height: var(--radius-l);
      padding: 0 var(--grid-s);
      font-size: var(--font-s);
      line-height: var(--radius-l);
      border-radius: var(--radius-m);
    }

    &.big {
      height: 50px;
      padding: 0 25px;
      font-size: var(--font-l);
      line-height: 50px;
      border-radius: 25px;
    }

    &.left {
      text-align: left;
    }

    &.pulse {
      transition: all .2s ease-in-out;
      animation: pulse 2s infinite;
    }

    &.outline {
      line-height: 40px !important;
    }

    &.fullwidth {
      width: 100%;
    }

    &.spinner {
      background-image: url("/assets/imgs/loader.gif");
      background-repeat: no-repeat;
      background-position: center;
      background-size: var(--grid-m);
      text-indent: -200px;
      overflow: hidden;
      color: var(--ion-color-primary);
    }

    &.small.spinner {
      width: var(--grid-xxl);
    }

    &.medium.spinner {
      width: var(--radius-l);
    }

    // Primary

    &.blue,
    &.primary {
      background-color: var(--ion-color-primary);
      font-size: var(--font-s);
      color: white;

      ion-icon,
      ion-spinner {
        color: white;
      }

      &.outline {
        background-color: inherit;
        border: 1px solid var(--ion-color-primary);
        color: var(--ion-color-primary);
      }

      span.right {
        color: var(--ion-color-primary);

        ion-icon,
        ion-spinner {
          color: var(--ion-color-primary);
        }
      }
    }

    // Secondary

    &.secondary {
      background-color: var(--ion-color-secondary);
      font-size: var(--font-s);
      color: var(--ion-color-background);

      ion-icon,
      ion-spinner {
        color: white;
      }

      &.outline {
        background-color: inherit;
        border: 1px solid var(--ion-color-secondary);
        color: var(--ion-color-secondary);
      }

      span.right {
        color: var(--ion-color-secondary);

        ion-icon,
        ion-spinner {
          color: var(--ion-color-secondary);
        }
      }
    }

    // Success

    &.success,
    &.green {
      background-color: var(--ion-color-success);
      font-size: var(--font-s);
      color: white;

      ion-icon,
      ion-spinner {
        color: white;
      }

      span.right {
        color: var(--ion-color-success);

        ion-icon,
        ion-spinner {
          color: var(--ion-color-success);
        }
      }
    }

    // Warning

    &.warning,
    &.orange {
      background-color: var(--ion-color-warning);
      font-size: var(--font-s);
      color: white;

      ion-icon,
      ion-spinner {
        color: white;
      }

      &.shadow {
        box-shadow: var(--ion-color-shadow);
      }

      &.outline {
        background-color: inherit;
        border: 1px solid var(--ion-color-warning);
        color: var(--ion-color-warning);
      }

      span.right {
        color: var(--ion-color-warning);

        ion-icon,
        ion-spinner {
          color: var(--ion-color-warning);
        }
      }
    }

    // Danger

    &.danger,
    &.red {
      background-color: var(--ion-color-danger);
      font-size: var(--font-s);
      color: white;

      ion-icon,
      ion-spinner {
        color: white;
      }

      &.shadow {
        box-shadow: var(--ion-color-shadow);
      }

      &.outline {
        background-color: inherit;
        border: 1px solid var(--ion-color-danger);
        color: var(--ion-color-danger);
      }

      span.right {
        color: var(--ion-color-danger);

        ion-icon,
        ion-spinner {
          color: var(--ion-color-danger);
        }
      }
    }

    // Grey

    &.grey {
      background-color: var(--ion-color-components);
      font-size: var(--font-s);
      color: var(--ion-color-secondary);
      border: 1px solid var(--ion-color-borders);

      ion-icon {
        color: var(--ion-color-secondary);
      }

      &.outline {
        background-color: inherit;
        border: 1px solid var(--ion-color-borders);
        color: var(--ion-color-secondary);
      }
    }

    // Tertiary

    &.mediumgrey {
      background-color: var(--ion-color-tertiary);
      font-size: var(--font-s);
      color: white;

      &.outline {
        background-color: inherit;
        border: 1px solid var(--ion-color-tertiary);
        color: var(--ion-color-tertiary);
      }

      ion-spinner {
        color: white;
      }
    }

    // Background

    &.background {
      background-color: var(--ion-color-background);
      color: var(--ion-color-secondary);
      border: 1px solid var(--ion-color-borders);

      &.tertiary {
        color: var(--ion-color-tertiary);
      }

      ion-spinner {
        color: var(--ion-color-secondary);
        width: var(--grid-s);
        height: var(--grid-s);
      }

      &.invites {
        font-size: 13px;
        font-weight: var(--font-semibold);
      }
    }

    &.outline {
      border: 1px solid var(--ion-color-secondary);
    }

    // White

    &.white {
      background-color: white;
      font-size: var(--font-s);
      color: var(--ion-color-dark);

      &.red {
        color: var(--ion-color-danger);
      }
    }

    // Transparent

    &.transparent {
      background-color: transparent;
      font-size: var(--font-s);
      color: white;

      &.outline {
        border: 1px solid white;
      }
    }

    &.outline {
      border: 1px solid var(--ion-color-secondary);
    }
  }
}